<template>
  <div>
    <v-card-actions v-if="show" class="py-0 mt-n6 px-0">
      <v-spacer />
      <BaseActionButton
        text
        class="charcoal paper--text mr-2"
        href
        icon="mdi-account-plus-outline"
        :label="buttonLabel"
        @clickedThis="$router.push('/register')"
      />
    </v-card-actions>
    <v-card-title class="py-0">
      <h4 class="charcoal--text">WHY USE BFIT</h4>
    </v-card-title>
    <v-card-text class="py-0">
      <v-list class="pa-0" dense>
        <v-list-item v-for="v in reasons" :key="v.id" class="pl-2 ">
          <v-list-item-icon class="mr-2">
            <v-icon class="charcoal--text">{{ iconNumber(v.id - 1) }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content :class="textSizeXSmall + ' charcoal--text'">
            <span v-html="v.text" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-row dense>
      <v-col cols="12">
        <template> </template>
      </v-col>
    </v-row>
    <template>
      <v-row dense>
        <v-col cols="12">
          <GetApp />
        </v-col>
      </v-row>
    </template>
  </div>
</template>
<script>
import util from '@/mixins/util.js'
import { appConfig } from '@/store/helpers.js'
import reasons from '@/json/bfitreasons.json'
const GetApp = () =>
  import(/* webpackPrefetch: true */ '@/components/blocks/GetApp.vue')
export default {
  components: { GetApp },
  mixins: [util],
  data: () => ({
    main: 0,
    reasons: reasons,
    volumeStats: {},
    invites: [],
    reps: {},
    moving: {},
    loaded: false,
    showStats: false
  }),
  beforeMount() {},

  props: {
    buttonLabel: {
      type: String,
      default: 'Register'
    },
    show: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    gradient() {
      return 'to top, rgba(220,250,250,.0), rgba(255,240,255,.2)'
    },

    ...appConfig
  },
  methods: {}
}
</script>
